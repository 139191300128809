import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Map from '../components/homepage/Map'
import HaveQuestions from '../components/StillHaveQuestions'
import ContactAnimation from '../components/ContactAnimation'
import GiftBoxAnimation from '../components/GiftBoxAnimation'
import TelephoneAnimation from '../components/TelephoneAnimation'
import ThankYouShoppingSummary from '../components/ThankYouShoppingSummary'
import { IconPrint, GnsLogo, IconShoppingCartDL } from '../components/Icons'
import useUrlState from '../hooks/useUrlState'
import { navigate } from 'gatsby'
import { clearCart } from '../state/cart'
import { useDispatch } from 'react-redux'
import { TrackUniqueEvent } from '../helpers/TrackingHelper'
import GoogleAds from '../../utils/GoogleAds'
import {
  calculateDeliveryDate } from '../components/products/EstimatedDeliveryDate'

const ShoppingThankYouPage = () => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [isReloading, setIsReloading] = React.useState(true)
  const [orderId] = useUrlState('id', null)
  const [orderUid] = useUrlState('uid', null)
  const [orderInfo, setOrderInfo] = React.useState({ order: {}, products: [] })
  const dispatch = useDispatch()
  const PrintButton = () => {
    const handlePrint = () => {
      window.print()
    }

    return (
      <button
        className="print:hidden group flex flex-row justify-center items-center gap-2 uppercase text-gray-500 hover:text-red-600 duration-300 w-max mx-auto px-4 py-1 border-2 border-gray-400 hover:border-red-600"
        onClick={handlePrint}
      >
        <IconPrint
          className={'w-5 h-5 stroke-2 stroke-gray-500 group-hover:stroke-red-600 duration-300 '}
        />{' '}
        Print
      </button>
    )
  }

  const removeHtmlTagsAndTrimSpaces = str => {
    // Replace HTML tags with a space
    const withoutTags = str.replace(/<\/?[^>]+(>|$)/g, ' ')
    // Remove multiple white spaces
    const trimmedString = withoutTags.replace(/\s+/g, ' ').trim()
    return trimmedString
  }
  const intervalRef = React.useRef()
  const fetchOrder = React.useCallback(async () => {
    setIsReloading(true)
    return fetch('/api/complete-checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: orderId,
        uid: orderUid,
      }),
    })
      .then(async res => {
        if (res.status === 404) {
          navigate('/404')
        }
        if (res.status !== 200) {
          throw new Error('Cannot fetch order status')
        }
        let data = await res.json()
        // process order info
        const order = data.order
        TrackUniqueEvent(
          {
            event: 'checkout_completed',
            id: {
              id: order.id,
              uid: order.uid,
              status: order.paymentStatus,
            },
          },
          {
            order_id: order.id,
            transaction_id: order.id,
            currency: 'USD',
            value: order.total,
            ...data.order,
            products: data.order.products.map(product => ({
              ...product,
              description: removeHtmlTagsAndTrimSpaces(product.description || ''),
            })),
          },
          {
            klaviyoEventName: 'Checkout Thank You Page',
            // Note: Complete Order (backend API) will send Checkout Complete 
            // event to Klaviyo
            criteo: {
              event: 'trackTransaction',
              id: order.id,
              currency: 'USD',
              item: data.order.products.map(product => ({
                id: product.sku,
                price: product.price,
                quantity: product.qty,
              })),
            },
          }
        )
        const mapProduct = (product, index) => ({
          item_id: product.sku,
          item_name: product.title,
          index,
          item_brand: product.brand,
          item_category: product.item_category,
          item_category2: product.item_type,
          item_category3: product.brand,
          item_category4: product.model,
          price: product.price,
          quantity: product.qty,
        })
        order.products.forEach((product, index) => {
          TrackUniqueEvent(
            {
              event: 'ordered_product',
              id: {
                order_id: order.id,
                product_id: product.sku,
              },
            },
            {
              product_id: product.sku,
              order_id: order.id,
              transaction_id: order.id,
              value: product.price,
              currency: 'USD',
              ...mapProduct(product, index),
            },
            {
              klaviyoEventName: 'Ordered Product',
            }
          )
        })
        TrackUniqueEvent(
          { event: 'purchase', id: order.id },
          {
            order_id: order.id,
            transaction_id: order.id,
            value: order.total,
            revenue_value: order.total,
            currency: 'USD',
            email: order.shipping.email,
            phone: order.shipping.phone,
            tax: order.tax,
            shipping: order.shippingCost,
            paymentMethod: order.paymentMethod,
            products: order.products.map((product, index) => mapProduct(product, index)),
          },
          {
            klaviyoEventName: 'Placed Order',
            googleAdsConversion: GoogleAds.Conversions.sale,
          }
        )
        if (!window.googleMerchantReview) {
          window.googleMerchantReview = true
          window.addScript('https://apis.google.com/js/platform.js?onload=renderOptIn')
          window.renderOptIn = function () {
            window.gapi.load('surveyoptin', function () {
              window.gapi.surveyoptin.render({
                merchant_id: 10751,
                order_id: order.id,
                email: order.shipping.email,
                delivery_country: 'US',
                estimated_delivery_date: calculateDeliveryDate(4, new Date(order.createdAt))
                  .toISOString()
                  .substring(0, 10),
              })
            })
          }
        }
        setOrderInfo(data.order)
        setIsLoading(false)
        setIsReloading(false)
        dispatch(clearCart())
        if (
          order.paymentStatus === 'paid' ||
          order.paymentMethod === 'BankWire' ||
          order.paymentStatus === 'declined'
        ) {
          clearInterval(intervalRef.current) // Stop the interval if payment status is final
        }
      })
      .catch(err => {
        setIsReloading(false)
        console.error(err)
      })
  }, [orderId, orderUid])

  React.useEffect(() => {
    if (!orderId && !orderUid) {
      return
    }
    fetchOrder()
    intervalRef.current = setInterval(fetchOrder, 5000)
    return () => clearInterval(intervalRef.current)
  }, [orderId, orderUid])

  return (
    <Layout className={'mx-auto'} breadcrumbs={false}>
      <Seo title="THANK YOU" noindex={true} />
      <div className="hidden print:block ">
        <div className="grid grid-cols-[1fr_auto_auto] w-full mr-0 gap-4 justify-end items-start max-w-[62.5rem] px-4">
          <div className="text-[3rem] font-black text-gray-400">WEB ORDER</div>
          <div className=" w-full flex flex-col items-end justify-start">
            <GnsLogo
              alt="Pre-owned Watches and Luxury Jewelry by Gray and Sons"
              className="w-[calc(8rem+2.5vw)] h-[calc(3rem+1vw)]"
            />
            <p className="text-xs text-right">
              WEB ORDER NUMBER
              <br />
              <span className="font-bold text-lg">8939829</span>
            </p>
          </div>
          <p className="max-w-max pt-2 border-l pl-4">
            9595 Harding Avenue
            <br />
            Surfside, Fl 33154
            <br />
            (305) 865-0999
            <br />
            www.grayandsons.com
          </p>
        </div>
      </div>
      {isLoading ? (
        <div className="w-full flex items-center flex-col min-h-screen ">
          <div
            className={
              'w-[40vw] h-[40vw] max-w-[300px] max-h-[300px] mx-auto flex-grow-0 animate-pulse '
            }
          >
            <IconShoppingCartDL className={'w-full text-gray-300'} />
          </div>
        </div>
      ) : (
        <section className="w-full mx-auto max-w-[62.5rem] text-lg px-4 xl:px-0 py-5">
          <div className="flex flex-col w-full">
            <h1 className="font-bold text-xl lg:text-3xl print:hidden">Order Details</h1>
            <div className="w-full flex flex-row justify-between items-center">
              <div>
                Order #{orderInfo?.id},{' '}
                {new Date(orderInfo.publishedAt || orderInfo.createdAt).toLocaleString()}
              </div>
              <div className="print:hidden hidden md:block">
                <PrintButton />
              </div>
            </div>
          </div>

          <ThankYouShoppingSummary
            order={orderInfo}
            fetchOrder={fetchOrder}
            isReloading={isReloading}
          />
          <div className="print:hidden">
            <h2 className="py-10 uppercase text-gray-500 text-2xl">What happens next?</h2>
            <ul className="grid grid-cols-1 lg:grid-cols-3 text-base max-w-[62.5rem] mx-auto">
              <li className="w-full mx-auto max-w-[400px] px-2">
                <div className="w-full aspect-square relative">
                  <div className="bg-gray-200 aspect-square rounded-full w-10/12 top-0 left-0 translate-x-[8.33%] translate-y-[8.33%] absolute -z-10" />
                  <ContactAnimation />
                </div>
                Your order will be processed soon! Please be sure to check your email for order
                details and confirmation.
              </li>
              <li className="w-full mx-auto max-w-[400px] px-2">
                <div className="w-full aspect-square relative">
                  <div className="bg-gray-200 aspect-square rounded-full w-10/12 top-0 left-0 translate-x-[8.33%] translate-y-[8.33%] absolute -z-10" />
                  <TelephoneAnimation />
                </div>
                One of our In-House Experts will contact you for additional details, shipping and
                delivery information.
              </li>
              <li className="w-full mx-auto max-w-[400px] px-2">
                <div className="w-full aspect-square relative p-4">
                  <div className="bg-gray-200 aspect-square rounded-full w-10/12 top-0 left-0 translate-x-[8.33%] translate-y-[8.33%] absolute -z-10" />
                  <GiftBoxAnimation />
                </div>
                All orders are Insured and shipped via FedEx Next Day Delivery. You will be notified
                by email when your order has shipped.
              </li>
            </ul>
            <hr className="mt-10" />
            <HaveQuestions />
          </div>
        </section>
      )}
      <Map />
    </Layout>
  )
}

export default ShoppingThankYouPage
